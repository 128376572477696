import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Layout from '../../components/layouts/project';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      arenaTracker: file(
        absolutePath: { regex: "/irythia-hs/arena-tracker.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      cardDb: file(absolutePath: { regex: "/irythia-hs/card-db.png/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      cardDbMobile: file(
        absolutePath: { regex: "/irythia-hs/card-db-mobile.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 400, height: 666, layout: CONSTRAINED)
        }
      }
      decklistTracker: file(
        absolutePath: { regex: "/irythia-hs/decklist-tracker.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 773, height: 924, layout: CONSTRAINED)
        }
      }
      overallStats: file(
        absolutePath: { regex: "/irythia-hs/overall-stats.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const screenshots = [
    {
      image: getImage(data.cardDb),
      title: 'Card Database',
      description: 'A searchable list of cards along with their attributes',
    },
    {
      image: getImage(data.cardDbMobile),
      title: 'Card Database',
      description: 'Mobile view of the card database',
    },
    {
      image: getImage(data.overallStats),
      title: 'Analytics Dashboard',
      description: 'General stats computed from all games played',
    },
    {
      image: getImage(data.arenaTracker),
      title: 'Arena Tracker',
      description:
        'Tracks info for a given arena run including individual games, the class and deck used, and final rewards',
    },
    {
      image: getImage(data.decklistTracker),
      title: 'Decklist Tracker',
      description: 'Tracks cards remaining within a deck while playing',
    },
  ];

  const [showLightbox, setLightboxToggle] = useState(false);
  const [gallerySelectedIndex, setGallerySelectedIndex] = useState(0);

  return (
    <Layout
      title="IrythiaHS"
      description="A set of companion tools for Hearthstone including a card database, a deck tracker, game analytics, and more."
    >
      <div>
        <h1 className="my-12 mx-8 text-center">IrythiaHS</h1>
        <div className="m-auto mb-12 px-8 max-w-2xl">
          <h2>About</h2>
          <p>
            <em>IrythiaHS</em> was started in early 2014 as a set of companion
            tools for <em>Hearthstone</em>, a popular free-to-play card game by
            Blizzard Entertainment. These tools included a card database to
            quickly look up card details, a &ldquo;friend finder&rdquo; for
            like-minded players to connect with each other, and a game tracker
            for improving gameplay via analytics. It also provided the first
            publicly available API for card data to enable the community in
            creating other tooling.
          </p>

          <ul>
            <li>
              <strong>Languages</strong>: Ruby, JavaScript, HTML, CSS
            </li>
            <li>
              <strong>Frameworks &amp; Libraries</strong>: Ruby on Rails,
              AngularJS, jQuery, D3.js, Bootstrap
            </li>
          </ul>

          <h2>Gallery</h2>
          <div className="my-4">
            <ModalGateway>
              {showLightbox ? (
                <Modal
                  onClose={() => setLightboxToggle(!showLightbox)}
                  allowFullscreen={false}
                >
                  <Carousel
                    views={screenshots.map(({ image, title }) => ({
                      source: getSrc(image),
                      caption: `${title}`,
                    }))}
                    currentIndex={gallerySelectedIndex}
                    styles={{
                      view: (base) => ({
                        ...base,
                        display: 'flex ',
                        justifyContent: 'center',
                        alignItems: 'center',

                        '& > img': {
                          margin: '2rem',
                          maxHeight: 'calc(100vh - 4rem)',
                        },
                      }),
                    }}
                  />
                </Modal>
              ) : null}
            </ModalGateway>

            <div className="screenshot-container">
              {screenshots.map(({ image, title, description }, index) => (
                <div
                  className="image-container"
                  key={index}
                  onClick={() => {
                    setGallerySelectedIndex(index);
                    setLightboxToggle(true);
                  }}
                >
                  <div className="aspect-ratio-container">
                    <div>
                      <GatsbyImage image={image} alt={title} />
                    </div>
                  </div>
                  <div className="description">
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
