import React from 'react';
import PropTypes from 'prop-types';
import Root from './root';
import SimpleNav from '../simple-nav';
import { projects } from '../../assets/stylesheets/projects.module.scss';

const Layout = ({ children, title, description }) => (
  <Root title={title} description={description}>
    <SimpleNav type="projects" />
    <div className={projects}>{children}</div>
  </Root>
);

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Layout;
